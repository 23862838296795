<template>
    <b-modal id="facility-edit-modal" title="Facility Edit Modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
      <template #modal-title>
        <h2 v-if="allowEdit" class="m-0">Edit Facility</h2>
        <h2 v-else class="m-0">View Facility</h2>
      </template>
      <validation-observer ref="facilityEditFormValidation">
        <b-form @submit.prevent>
          <b-row>
            <b-col>
              <b-form-group label="Name" label-for="name">
                <template #label>
                  Name <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Name"
                      :rules="{ required, regex: /^[0-9A-Z-()#&''\s]*$/i }">
                      <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false : null"
                      placeholder="Name" :disabled="!allowEdit" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
                <b-form-group label-for="icon">
                  <template #label> Icon </template>
                  <!-- <b-form-file
                    id="icon"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    :accept="acceptedFiles.join(', ')"
                    @change="uploadMessPicture"
                    :file-name-formatter="formatMessPictureNames"
                  /> -->
                  <b-form-file v-model="icon" class="mt-0"
                    :state="Boolean(icon)" :accept="acceptedFiles.join(', ')"
                    @change="uploadIcon($event)" placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..." ref="icon" :disabled="!allowEdit"/>
                  <!-- <small class="text-danger" v-if="documentError === 3">
                    Supported File Types are {{ acceptedFiles.join(", ") }}
                  </small> -->
                  <!-- <div class="d-flex flex-wrap">
                    <b-badge
                      pill
                      variant="primary"
                      v-for="(file, index) in pictures"
                      :key="index"
                      class="mr-50 mt-50"
                    >
                      {{ file.name }}
                      <feather-icon
                        size="24"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeMessPicture(index)"
                      />
                    </b-badge>
                  </div> -->
                </b-form-group>
             </b-col>
            </b-row>
            <template v-if="icon">
                <b-row class="mb-1">
                    <b-col sm="12" md="12" lg="12" xl="12">
                        <b-img :src="iconURL" rounded fluid > </b-img>
                    </b-col>
                </b-row>
            </template>
            <template v-if="allowEdit">
                <b-form-group class="text-right">
                    <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
                        Update
                    </b-button>
                </b-form-group>
            </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
    },
    mixins: [util],
    props: ["showModal", "facility", "allowEdit"],
    data() {
      return {
        perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
          useBothWheelAxes: false,
          suppressScrollX: true,
        },
        name: "",
        icon: null,
        iconURL: "",
        required,
        acceptedFiles: [".svg",".png"],
      };
    },
    async mounted() {
      if (this.showModal) {
        if(this.facility){
            try {
                this.name = this.facility.name
                this.iconURL = this.facility.icon;

                if (this.facility.icon) {
                fetch(this.facility.icon)
                    .then((response) => response.blob())
                    .then((blob) => {
                    this.$refs.icon.files.push(new File([blob], this.facility.icon.split('/').pop()))
                    this.icon = this.$refs.icon.files[0]
                    });
                }
            } catch (error) {
            this.displayError(error);
            }
        }
      }
    },
    methods: {
      ...mapActions({
        updateFacility: "appData/updateFacility",
      }),
      async validationForm() {
        const success = await this.$refs.facilityEditFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
          let formData = new FormData();
          let dataToInsert = {
            name: this.name,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
          };
          formData.append("data", JSON.stringify(dataToInsert));
          if (this.icon)
          {
            formData.append("icon", this.icon)
          }

          const res = await this.updateFacility({
            payload: formData,
            pk: this.facility.id,
          });

          if (res.status === 200) {
            this.$swal({
              title: "Facility updated successfully",
              icon: "success",
            });
            // await this.resetModal();
            this.$nextTick(() => {
              this.$bvModal.hide("facility-edit-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      async uploadIcon(event) {
        try {
            this.icon = null;
            if (!event.target.files[0]) {
            return;
            }
            if(!(event.target.files[0].type.includes("image/svg")) && !(event.target.files[0].type.includes("image/png"))) {
            this.$refs.icon.reset();
            // this.$swal({
            //     title: "Please upload an svg image",
            //     icon: "error",
            // });
            return;
            }
            this.icon = event.target.files[0];
            this.iconURL = URL.createObjectURL(this.icon)
        } catch (error) {
            this.displayError(error);
        }
        },



      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
    },
    watch: {
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  </style>
  